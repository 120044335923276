import React, {useState} from "react";

const TOAST_DURATION = 3000;

type ToastContextType = {
    toastContent: any,
    toastOpen: boolean,
    displayMessage: (message: string) => void,
    displayWarning: (message: string) => void,
    displayError: (message: string) => void,
}

const ToastContext = React.createContext<ToastContextType | undefined>(undefined);

function useToastContextValue() {

    const [toastContent, setToastContent] = useState<any | undefined>(undefined);
    const [toastOpen, setToastOpen] = useState(false);

    function displayMessage(message: string) {
        displayToast(
            <div className="bg-green-600 p-2 rounded-lg text-white font-medium shadow-lg">
                {message}
            </div>
        );
    }

    function displayWarning(message: string) {
        displayToast(
            <div className="bg-yellow-600 p-2 rounded-lg text-black font-medium shadow-lg">
                {message}
            </div>
        );
    }

    function displayError(message: string) {
        displayToast(
            <div className="bg-red-600 p-2 rounded-lg text-white font-medium shadow-lg">
                {message}
            </div>
        );
    }

    function displayToast(content: any) {
        setToastContent(content);
        setToastOpen(true);
        setTimeout(() => {
            setToastOpen(false);
        }, TOAST_DURATION);
    }

    return {
        toastContent, toastOpen,
        displayMessage, displayWarning, displayError
    }
}

function ToastProvider({children}: { children: React.ReactNode }) {
    const value = useToastContextValue();
    return <ToastContext.Provider value={value}>{children}</ToastContext.Provider>
}

function useToast() {
    const context = React.useContext(ToastContext);
    if (!context) {
        throw new Error('useToast must be used within a ToastProvider');
    }
    return context;
}

export {
    ToastProvider,
    useToast,
}
